// scss-lint:disable all
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

// Colours
// $muted-blue: #475d9a;
$muted-blue: #3E4B80;
$muted-grey: rgba(151, 151, 151, .5);
$subtle-grey: rgba(151, 151, 151, .3);
$green: #8FC640;
$light-blue: #0D73BB;

// Bootstrap Overrides
$theme-colors: (
 primary: $muted-blue,
);
@import '../../node_modules/bootstrap/scss/bootstrap.scss';


html, body, svg {
	font-family: 'Nunito Sans', sans-serif;
	font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
	color: $muted-blue;
	font-weight: bold;
}

.container {
	overflow-x: hidden;
}

a {
	color: $muted-blue;
}

select {
	&.selected {
		background-color: $muted-blue !important;
		color: white !important;
	}
}

option {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 1.5rem;
}

.App {
}

/* Basic table styling */
table {
	width: 100%;
	border-collapse: collapse;
}

th {
	color: $muted-blue;
	font-weight: bold;
	border: 1px solid $subtle-grey;
	padding: 5px;
}

td {
	border: 1px solid $subtle-grey;
	padding: 5px;
}

/* Form Controls */
.form-control {
	background-color: #ececec;

	&:active, &:focus {
		background-color: #ececec;
		box-shadow: none;
		border: 1px solid #ced4da;
	}
}

/* Header */
.App-header {
	margin: 20px -15px; //-15px because of a bootstrap thing
	height: 105px;
	@include media-breakpoint-down(xs) {
		margin: 0 -15px; //-15px because of a bootstrap thing
		height: 90px;
	}

	.header-links {
		display: inline-block;

		.header-link {
			display: inline-block;
			text-align: center;
			color: $muted-blue;
			font-size: 0.7rem;
			line-height: 0.75rem;
			width: 45px;
			margin-right: 13px;
			vertical-align: top;

			&:last-child {
				margin-right: 0;
			}

			svg {
				margin-bottom: 6px;
			}
		}
	}

	.logo-left {
		display: inline-block;
		margin-right: 15px;
	}

	.App-title {
		display: inline-block;
		margin: 0;
		color: $muted-blue;
		font-weight: 700;
		font-size: 2rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.3rem;
		}
	}

	.site-logo {
		width: 230px;

		@include media-breakpoint-down(sm) {
			width: auto;
			height: 52px;
		}
	}
}

.school-address {
	margin-top: 3px;
	font-size: 0.8rem;
	font-style: italic;
	font-weight: lighter;

	svg {
		margin-right: 6px;
	}
}

/* Search */
.search-box {
	position: relative;

	input[type=text] {
		text-indent: 18px;
	}

	svg {
		position: absolute;
		top: 8px;
		left: 8px;
		color: #495057; 
	}
}

/* Card Listing View */
.listing-sidebar {
	font-size: .9rem;
	.form-group {
		margin-bottom: 8px;
	}

	.filter-group {
		padding: 6px;
		border: 1px solid #ced4da;
		border-radius: 4px;
		color: #495057;
		background-color: #ececec;

		label {
			font-size: 0.8rem;
		}

		.group-title {
			font-size: 0.9rem;	
			cursor: pointer;

			svg {
				margin-right: 8px;
			}
		}

		.group-body {
			margin-top: 5px;

			.form-group:last-child {
				margin-bottom: 4px;
			}
		}

		&.open {
			background-color: white;

			.group-title {
				color: $muted-blue;
			}
		}
	}
}

.listing-body {
}

.map-view {
	clear: both;
}

.listing-nav {
	margin-bottom: 6px;
	color: #4a4a4a;
	min-height: 34px;
	font-size: .8rem;

	.col.sort {
		text-align: left;

		@include media-breakpoint-down(sm) {
			padding-top: 10px;
			text-align: center;
		}
	}

	.col.view {
		text-align: right;

		@include media-breakpoint-down(sm) {
			padding-top: 10px;
			text-align: center;
		}
	}

	.view-nav {
		display: inline-block;
		width: 255px;

		div.view-label {
			display: inline-block;
			margin-top: 5px;
		}
	}
	
	ul.sort-links, ul.view-links {
		margin: 0;
		margin-left: 15px;
		padding: 0;
		display: inline-block;
		font-size: .8rem;

		li {
			display: inline-block;
			list-style: none;
			margin-right: 10px;
			padding: 3px 6px;
			font-size: .8rem;

			button.btn-link {
				font-weight: normal;
				color: $muted-blue;
				text-decoration: none;
				padding: 0;
				font-size: .8rem;
			}

			&.active, &:hover {
				border: 1px solid $subtle-grey;

				button.btn-link {
					font-weight: bold;
				}
			}

		}
	}
}

.card-listing-header {
	width: 100%;
	margin-bottom: 12px;
	padding: 10px;
	background-color: $muted-blue;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50);
	color: white;
	clear: both;
}

.cesa-header {
	margin: 4px 0 0 0;
	padding: 0;
	font-size: 1.2rem;
	svg {
		margin-right: 11px;
		font-size: 1.4rem;
	}
}

.authorizer-header {
	h1 {
		font-size: 1.25rem;
		font-weight: 600;
		letter-spacing: 1px;
		color: white;
	}

	h2 {
		font-weight: lighter;
		font-size: .7rem;
		margin-bottom: 2px;
	}
	
	.cesa {
		font-size: 1.2rem;
		
		svg {
			margin-right: 8px;
		}
	}

	.address {
		font-size: .7rem;

		svg {
			margin-right: 5px;
		}

		div {
			display: inline-block;
			font-weight: lighter;
		}
	}
	
	.contact-info {
		font-weight: lighter;
		text-align: left;
		min-width: 150px;

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;
			line-height: 1.4rem;

			div {
				display: inline-block;
				margin-left: 10px;
				font-size: 0.8rem;
				max-width: 80%;
				line-height: 1rem;
				vertical-align: middle;
			}

			svg {
				font-size: 1rem;
				vertical-align: middle;
			}
		}
	}
}

.school.card {
	margin-bottom: 15px;
	height: 275px;
	width: calc(50% - 8px);
	max-width: 450px;
	float: left;

	@include media-breakpoint-down(xs) {
		width: 100%;
		height: 190px;
	}

	&:nth-child(odd) {
		margin-right: 15px;
	}

	&:nth-child(even) {
		margin-right: 0;
	}

	.card-header {
		padding: 6px 10px;

		.school-label {
			font-size: 0.8rem;
			float: right;
			color: #fff;
			background-color: #475D9A;
			margin-top: 3px;
			padding: 0 3px;
			border-radius: 4px;
		}
	}

	.card-body {
		padding: 10px 10px 0 10px;
		position: relative;

		& > div {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: calc(100% - 20px);
			text-align: center;

			@include media-breakpoint-down(xs) {
				padding-top: 10px;
			}

			div {
				display: inline-block;
				text-align: left;
			}
		}
	}

	.card-footer {
		padding: 5px 10px 10px 10px;
		background-color: white;
		border: 0;
		font-size: 0.8rem;

		@include media-breakpoint-down(xs) {
			padding: 5px 10px;
		}
	}

	.school-title {
		color: $muted-blue;
		font-weight: bold;
		font-size: 1.1rem;
		vertical-align: middle;

		@include media-breakpoint-down(xs) {
			font-size: .9rem;
		}

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		
		svg {
			font-size: 1rem;
			margin: 0 6px 0 0;
			position: relative;
			top: -2px;
		}
	}
	
	.school-address {
		margin-top: 3px;
		font-size: 0.8rem;

		@include media-breakpoint-down(xs) {
			font-size: 0.75rem;
		}

		font-style: italic;
		font-weight: lighter;

		svg {
			margin-right: 6px;
		}
	}

	.contact-info {
		display: inline-block;
		margin-bottom: 10px;
		font-size: 0.85rem;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
		}

		svg {
			margin-right: 8px;
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				line-height: 1.15rem;
				margin-bottom: 8px;
			}
		}
	}

	.school-attributes {
		font-size: 0.75rem;
		p {
			margin-bottom: 0.1rem;
		}
	}

	.more {
	}
}

/* School Listing */
.school-listing {
	@include media-breakpoint-down(xs) {
		font-size: .9rem;
	}

	h1 {
		font-size: 1.6rem;

		@include media-breakpoint-down(xs) {
			font-size: 1.3rem;
		}

		font-weight: bold;
		color: $muted-blue;
		margin-bottom: 5px;
	}

	h2 {
		font-size: 1.2rem;

		@include media-breakpoint-down(xs) {
			font-size: 1rem;
		}

		font-weight: bold;
		color: $muted-blue;
	}
	
	p {
		font-weight: lighter;

	}

	.header {
		padding-bottom: 6px;
		margin-bottom: 22px;
		border-bottom: 1px solid $muted-grey;

		.back-link {
			font-size: .9rem;
			margin-bottom: 18px;
		}

		.school-est {
			@include media-breakpoint-down(xs) {
				font-size: .8rem;
			}

			margin-top: 10px;
			float: right;
			padding: 7px 10px;
			color: white;
			font-weight: normal;
			border-radius: 4px;
			background-color: #626262;
		}
	}
	
	.contact-info {
		font-weight: lighter;

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;
			line-height: 1.5rem;

			div {
				display: inline-block;
				margin-top: -5px;
				margin-left: 10px;
			}

			svg {
				font-size: 1.1rem;
			}
		}
	}

	.authorizer-card {
		margin: 30px auto;
		width: 85%;
		display: block;
	}

	.school-attributes {
		border: 1px solid $subtle-grey;
		margin: 16px 0;
		padding: 15px 20px;
		font-weight: lighter;

		span.label {
			font-weight: bold;
			color: $muted-blue;
		}
	}

	.more-info {
		margin-top: 30px;
		text-align: center;

		p.emphasize { 
			font-size: 1.1rem;
		}
		
		svg {
			margin-right: 5px;
		}
	}

	.request-update {
		text-align: center;
		margin-top: 20px;
	}
}

.authorizer-card {
	display: inline-block;
	margin-top: 55px;
	font-weight: lighter;
	padding: 10px;
	background-color: $muted-blue;
	color: white;
	border-radius: 8px;	
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	text-align: left;

	.title {
		font-weight: bold;
		font-size: 1.1rem;
		padding-bottom: 5px;
		margin-bottom: 5px;
		border-bottom: 1px solid rgba(255,255,255,0.3);
	}

	a { color: white; }

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
		line-height: 1.8rem;

		div {
			display: inline-block;
			margin-left: 10px;
			font-size: 0.8rem;
			max-width: 80%;
			line-height: 1rem;
			vertical-align: middle;
		}

		svg {
			font-size: .9rem;
			vertical-align: middle;
		}
	}
}

/* Map View */
.school-map-info-window {
	font-family: 'Open Sans', sans-serif;
	width: 300px;
	min-height: 100px;

	div.title {
		color: $muted-blue;
		font-size: 1.2rem;
		margin-bottom: 8px;
		font-weight: bold;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	div.cesa {
		font-size: .8rem;
		font-weight: bolder;
		line-height: 1rem;
		margin-top: 7px;
		float: right;
	}

	div.school-address {
		text-align: center;
		margin-bottom: 7px;
	}

	.school-map-info-window-footer {
		text-align: right;
	}
}

/* Printable listing */
@media print {
	@page {
		size: letter;
	}

	.title-page {
		height: 100%;
	}
}

.page-break {
	page-break-after: always;
}


/* Footer */
.footer {
	margin: 50px 0;
	padding-top: 20px;
	border-top: 1px solid $subtle-grey;
	min-height: 100px;
	text-align: center;
	color: grey;
	font-size: 0.8rem;

	.footer-info {
		margin-bottom: 20px;
	}
}


/* Infographic Styling */
.infographic {
	text-align: center;
	color: $muted-blue;

	.infographic-grid {
		width: 90%;
		margin: 20px auto 20px auto;
		padding-top: 20px;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-gap: 0;


		.info-cell {
			width: 100%;
			min-height: 350px;
			padding: 0px;
			display: flex;
			justify-content: center;
			align-items: center;

			&.wide {
				grid-column-end: span 2;
			}


			&>div {
				padding: 20px 30px;
			}

			&.var-height {
				min-height: 0;
				&>div {
					padding: 0px 20px;
				}
			}

			.chart-container {
				height: 330px;
			}

			.title {
				font-size: 2.3rem;
				display: flex;
				justify-content: center;
				align-items: center;

				&>span{
					font-size: 140%;
				}
			}

			&.green-bg {
				background-color: $green;
				color: white;
				a {
					color: white;
					text-decoration: underline;
				}
			}

			&.blue-bg {
				background-color: $muted-blue;
				color: white;
				a {
					color: white;
					text-decoration: underline;
				}
			}

			&.light-blue-bg {
				background-color: $light-blue;
				color: white;
				a {
					color: white;
					text-decoration: underline;
				}
			}

			&.green-fg {
				color: $green;
			}

			&.blue-fg {
				color: $muted-blue;
			}

			&.light-blue-fg {
				color: $light-blue;
			}

			&.green-accent {
			}

			&.blue-accent {
			}

			&.light-blue-accent {
			}
		}

		.chart-title {
			font-weight: bold;
			margin-bottom: 0.5rem;
		}

		.big-number {
			font-size: 5rem;
			line-height: 5rem;
			font-weight: bold;
		}

		.big-number-label {
			font-size: 2.5rem;
			line-height: 2.5rem
		}
	}
}

.credential-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 600px;
	margin: 0 auto;
}

.credential-card {
	width: 250px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	padding: 10px 15px;
	margin: 0 10px 10px 0;
	text-align: left;
	font-size: 0.7rem;

	.date-awarded {
		font-size: .9rem;
	}
	
	img {
		margin: 0 0 10px 0;
		width: 100%;
	}
}

.credential-icon-card {
	display: inline-block;
	margin: 0 3px 3px 0;

	img {
		max-width: 30px;
		max-height: 30px;
	}
}

.printable {
	-webkit-print-color-adjust: exact;

	.authorizer-header, .header-group-printable {
		page-break-inside: avoid;
	}

	.title-page {
		position: relative;
		height: 1300px;
		border: 1px solid $muted-blue;
		box-sizing: border-box;
		text-align: center;

		.vertical-center {
			position: absolute;
			top: 35%;
			left: 50%;
			transform: translateY(-50%);
			transform: translateX(-50%);
		}

		.site-logo {
			width: 400px; 
		}
	}
	
	.infographic {
		.infographic-grid {
			width: 100%;
			margin-top: 0;
			padding-top: 0;

			.info-cell {
				page-break-inside: avoid;
				min-height: 250px;

				.chart-container {
					height: 275px;
					width: 400px;
				}

				.title {
					font-size: 2em;
				}
			}
		}
	}

	.school-listing {
		margin: 30px 0;
		page-break-inside: avoid;
	}

	.request-update {
		display: none;
	}
}

.embedded-video {
	text-align: center;

	iframe {
		width: 500px;
		height: 281px;
	}
}

.video-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
}
