$muted-blue: #475d9a;

.loader-fullpage {
	position: relative;
  top: 50%;
  transform: translateY(-50%);
	height: calc(100vh - 280px);
	text-align: center;
}

.loader-title {
	font-size: 1.3rem;
	color: $muted-blue;
}

/* Pulled from https://loading.io/css/ */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;
}
.lds-grid div {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: $muted-blue;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 12px;
  left: 12px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 12px;
  left: 52px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 12px;
  left: 90px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 52px;
  left: 12px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 52px;
  left: 52px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 52px;
  left: 90px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 90px;
  left: 12px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 90px;
  left: 52px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 90px;
  left: 90px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
